@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.toolbar {
    display: flex;
    justify-content: center;
    padding: 0.5rem;
}

.toolbarInstant {
    display: flex;
    justify-content: center;
    padding: 0.2rem;
}

.btn-on-over .btn-remove {
    display: none;
}

.btn-on-over:hover .btn-remove {
    display: block;
}

.thumb,
.thumb::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}

/* Removing the default appearance */
.thumb {
    pointer-events: none;
    position: absolute;
    height: 0;
    width: 200px;
    outline: none;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
    background-color: #f1f5f7;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
    background-color: #f1f5f7;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
}

.monitor-table-wrapper::-webkit-scrollbar {
    display: none;
}

.dropdown-hidden-scrollbar::-webkit-scrollbar {
    display: none;
}

.dropdown-hidden-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.monitor-table-wrapper {
    height: calc(100vh - 2rem);
    width: calc(100vw - 16.5rem);
}

.monitor-table-header-wrapper {
    width: calc(100vw - 16.5rem);
}

.monitor-table-header-wrapper-lg {
    width: calc(100vw - 14.5rem);
}

.monitor-table-wrapper-1440 {
    height: calc(100vh - 7rem);
}

.menu-list li:last-child {
    border-bottom-width: 1px;
}

.show-page-name span {
    display: none;
}

.show-page-name:hover span {
    display: block;
    position: absolute;
    left: 4rem;
    top: 0.2rem;
    z-index: 100;
    color: white;
    background: rgb(89, 105, 116);
    padding: 0.2rem;
    border-radius: 10px;
    border-color: white;
}

.expand-menu {
    height: calc(100vh - 14.5rem);
    position: absolute;
}

@keyframes pop {
    0% {
        transform: scale(1);
        color: inherit;
    }
    50% {
        transform: scale(1.2);
        filter: blur(3px);
    }
    100% {
        transform: scale(1);
        color: inherit;
    }
}

.animate-pop {
    animation: pop 0.3s ease-in-out;
}